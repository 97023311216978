import { LoginState } from "./login.state";
import axios from "axios";

//const baseUrl = "https://localhost:5001/api";
const baseUrl = "https://bloinka2021.azurewebsites.net/api";

export const apiOrderData = async (force: boolean, login: LoginState) => {
  //   const response = await Promise.all([
  //     fetch(
  //       `${baseUrl}/BagOrder/orderwaiting?ID_clients=${login.ID_clients.toString()}&tabletName=${
  //         login.device
  //       }&accessCode=${login.accessCode}`
  //     ),
  //   ]);

  try {
    const response = await axios.get(
      `${baseUrl}/BagOrder/orderwaiting?ID_clients=${login.ID_clients.toString()}&tabletName=${
        login.device
      }&accessCode=${login.accessCode}`
    );

    const statusCode = Number(response.data) || 0;

    if (statusCode === 1 || force) {
      const ordersWaiting = await axios.get(
        `${baseUrl}/BagOrder/getBagOrdersByClient?ID_clients=${login.ID_clients.toString()}&count=25&accessCode=${
          login.accessCode
        }`
      );
      if (response.data === "") {
        return {
          items: [],
          hasPending: false,
          lastPullHash: "",
          loading: false,
          message:
            "There was an error retrieving orders.  Click the Orders icon to try again. Check you username/password",
        };
      } else {
        return {
          items: ordersWaiting.data,
          hasPending: force ? false : true,
          lastPullHash: "",
          loading: false,
          message: "",
        };
      }
    } else {
      return { loading: false, message: "" };
    }
  } catch (ex: any) {
    return {
      loading: false,
      message:
        ex.message +
        " - There was an error retrieving orders.  Click the Orders icon to try again. Check you username/password ",
    };
  }
};

export const apiTabletCheckForRestart = async (login: LoginState) => {
  const response = await axios.post(`${baseUrl}/Tablet/CheckRestart`, {
    ID_clients: login.ID_clients.toString(),
    deviceName: login.device,
  });

  return response.data as any;
};

export const apiAccessCodeVerify = async (
  ID_clients: number,
  accessCode: string
) => {
  const response = await axios.get(
    `${baseUrl}/Tablet/login?ID_clients=${ID_clients.toString()}&password=${accessCode}`
  );

  return response.data as string;
};

export const apiVendorData = async () => {
  try {
    const response: any = await axios.get(`${baseUrl}/Vendor?key=VENDOR_3.0`);

    const vendorData = response.data as any;

    return vendorData;
  } catch (ex: any) {
    return {};
  }
};

export const bagOrderSetStatus = async (
  bagGuid: string,
  message: string,
  login: LoginState
) => {
  let mins = 0;
  let status = 5;

  if (message === "Reject") {
    mins = -1;
    status = 7;
  } else if (message === "Accept") {
    mins = -1;
    status = 5;
  } else if (message === "Not Pickup") {
    mins = -1;
    status = 8;
  } else if (message === "Not Pickup - Undo") {
    mins = -1;
    status = 9;
  } else {
    mins = Number.parseInt(message);
  }

  const response: any = await axios.get(
    `${baseUrl}/Bagorder/setbagbycode?bagguid=${bagGuid}&accessCode=${login.accessCode}&status=${status}&mins=${mins}`
  );

  const rtn = response.data as any;

  console.log(rtn);

  return 1;
};

export const apiMenuData = async () => {
  const response: any = await axios.get(
    `${baseUrl}/Product/GetJson?docKey=ORDERMENU_2.0`
  );

  const menuJson = response.data as any;

  let allProducts: any[] = [];

  menuJson.Sections.forEach((parent: any) => {
    parent.Items.forEach((child: any) => {
      if (child.Key.indexOf("PIZZA") === 0) {
        child.PizzaSizes = parent.PizzaSizes;
        child.PizzaSetups = parent.PizzaSetups;
        child.SauceAmount = parent.SauceAmount;
        child.SauceType = parent.SauceType;
      }

      if (child) allProducts.push(child);
    });
  });

  return allProducts;
};

import { ActionType } from "../types";
import { apiAccessCodeVerify } from "./api";
import { LoginState } from "./login.state";

export const setLoginData = (data: Partial<LoginState>) =>
  ({
    type: "set-login-data",
    data,
  } as const);

export const setLoginDataAction =
  (data: LoginState) => async (dispatch: React.Dispatch<any>) => {
    if (data.ID_clients === 0) {
      localStorage.clear();

      dispatch(
        setLoginData({
          accessCode: "",
          device: "",
          ID_clients: 0,
          isValid: false,
        })
      );
    }

    const res = await apiAccessCodeVerify(data.ID_clients, data.accessCode);
    if (res.toString() === "1") {
      localStorage.setItem("accessCode", data.accessCode);
      localStorage.setItem("device", data.device);
      localStorage.setItem("ID_clients", data.ID_clients.toString());

      dispatch(
        setLoginData({
          accessCode: data.accessCode,
          device: data.device,
          ID_clients: data.ID_clients,
          isValid: true,
        })
      );
    } else {
      dispatch(setLoginData({ isValid: false }));
    }
  };

export const loadLoginDataAction =
  () => async (dispatch: React.Dispatch<any>) => {
    const accessCode = localStorage.getItem("accessCode") || "";
    const device = localStorage.getItem("device") || "";
    const ID_clients = Number(localStorage.getItem("ID_clients")) || 0;

    const res = await apiAccessCodeVerify(ID_clients, accessCode);

    if (res.toString() === "1") {
      dispatch(setLoginData({ accessCode, device, ID_clients, isValid: true }));
    }
  };

export type LoginActions = ActionType<typeof setLoginData>;

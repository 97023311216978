import { ActionType } from "../types";
import { apiMenuData } from "./api";
import { ProductsState } from "./products.state";

export const setData = (data: Partial<ProductsState>) =>
  ({
    type: "set-products-data",
    data,
  } as const);

export const loadProductsDataAction =
  () => async (dispatch: React.Dispatch<any>) => {
    const data = await apiMenuData();
    dispatch(setData({ items: data }));
  };

export type ProductsActions = ActionType<typeof setData>;

import * as React from "react";
import { ProductsState } from "../context/data/products.state";
import ProductView from "./ProductView";
import PizzaView from "./PizzaView";

interface IOrderDetailProps {
  order: any;
  products: ProductsState;
  view: any;
}

const OrderDetail: React.FC<IOrderDetailProps> = ({
  order,
  products,
  view,
}) => {
  const cart = JSON.parse(order.cartJSON);

  return (
    <div className="orderDetail">
      {/* <button onClick={() => view({ view: "orders" })} className="backButton">
        Back to Orders
      </button> */}

      {cart.Notes.length > 0 && (
        <div className="notes">
          <b>Special Instructions:</b>
          <div>{cart.Notes}</div>
        </div>
      )}

      {cart.items.map((ci: any, idx: number) => {
        const productMatch: any = (products.items &&
          products.items.find((s: any) => s.Key === ci.ProductKey)) || {
          Key: "",
        };

        if (ci.ProductKey.startsWith("PIZZA"))
          return (
            <PizzaView
              key={`item_${idx}`}
              item={ci}
              product={productMatch}
            ></PizzaView>
          );
        else
          return (
            <ProductView
              key={`item_${idx}`}
              item={ci}
              product={productMatch}
            ></ProductView>
          );
      })}
    </div>
  );
};

export default OrderDetail;

import { useRef, useState } from "react";
import { setLoginDataAction } from "../context/data/login.actions";
import { LoginState } from "../context/data/login.state";
import { setOrdersData } from "../context/data/orders.actions";

interface ISettingsProps {
  login: LoginState;
  setLoginDataAction: typeof setLoginDataAction;
  setOrdersData: typeof setOrdersData;
  view: any;
}

const loginHtml = (
  tabletName: any,
  accessCode: any,
  ID: any,
  view: any,
  setLoginDataAction: any
) => (
  <>
    <input type="hidden" value="tablet" ref={tabletName} />
    <label htmlFor="ID">ID:</label>
    <input type="tel" name="ID" ref={ID} />

    <label htmlFor="accessCode">Access Code</label>
    <input
      type="text"
      name="accessCode"
      autoCapitalize="none"
      ref={accessCode}
    />

    <input
      type="button"
      onClick={() => {
        if (ID.current?.value !== "" && accessCode.current?.value !== "") {
          setLoginDataAction({
            ID_clients: Number(ID.current?.value) || 0,
            accessCode: accessCode.current?.value || "",
            device: tabletName.current?.value || "",
          });
          console.log("greg");
          view({ view: "orders" });
        } else {
          alert("You must supply an ID and an Access Code");
        }
      }}
      value="Save"
    />
  </>
);

const loggedIn = (
  login: any,
  setOrdersData: any,
  setSoundOn: any,
  soundOn: boolean,
  setLoginDataAction: any
) => {
  return (
    <div className="settingsHolder">
      <button
        onClick={() => {
          setOrdersData({ hasPending: !soundOn });

          setSoundOn(!soundOn);
        }}
      >
        Test Notification Sound ({soundOn ? "On" : "Off"})
      </button>

      <button
        onClick={() => {
          const password = prompt("Please supply password to logout");

          if (password === login.accessCode) {
            setLoginDataAction({
              ID_clients: 0,
              accessCode: "",
              device: "",
              isValid: false,
            });
          } else {
            alert("Invalid password");
          }
        }}
      >
        Log out
      </button>

      <button
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Restart App
      </button>

      <button
        onClick={() => {
          window.location.href = "//orders.bloinka.com";
        }}
      >
        Use Previous Version
      </button>
    </div>
  );
};

const Settings: React.FC<ISettingsProps> = ({
  login,
  setLoginDataAction,
  view,
  setOrdersData,
}) => {
  const [soundOn, setSoundOn] = useState(false);

  const tabletName = useRef<HTMLInputElement>(null);
  const ID = useRef<HTMLInputElement>(null);
  const accessCode = useRef<HTMLInputElement>(null);

  return (
    <div className="loginForm">
      {login.isValid
        ? loggedIn(
            login,
            setOrdersData,
            setSoundOn,
            soundOn,
            setLoginDataAction
          )
        : loginHtml(tabletName, accessCode, ID, view, setLoginDataAction)}
    </div>
  );
};

export default Settings;

import * as React from "react";
import { OrdersState } from "../context/data/orders.state";
import moment from "moment";
import { FaSpinner, FaAndroid, FaApple, FaChrome } from "react-icons/fa";

interface IOrderListProps {
  orders: OrdersState;
  view: any;
  setOrdersData: any;
}

const displayAcceptedTime = (order: any) => {
  const diff: number = Number(
    moment
      .duration(moment(order.acceptedTime).diff(moment(order.submittedTime)))
      .asMinutes()
      .toFixed(0)
  );

  let statusMessage = "";
  switch (order.orderStatus) {
    case 5:
      statusMessage = "Accepted";
      break;
    case 7:
      statusMessage = "Rejected";
      break;
    case 8:
      statusMessage = "No Show";
      break;
  }

  return (
    <div className="status">
      {statusMessage} - {diff.toString()} min
    </div>
  );
};

const OrderList: React.FC<IOrderListProps> = ({
  orders,
  view,
  setOrdersData,
}) => {

  

  return (
    <div className="orderList">
      {orders.loading && <FaSpinner className="icon-spin"></FaSpinner>}
      {!orders.loading && <h3>Orders</h3>}
      {orders &&
        orders.items &&
        orders.items.map((order: any) => {

          let origin;

          try {
            const cartJSON = JSON.parse(order.cartJSON);
            switch (cartJSON.source.platform) {
              case "web":
                origin = <FaChrome style={{fontSize: "14px", color: "#555"}}></FaChrome>;
                break;
              case "ios":
                origin = <FaApple style={{fontSize: "14px", color: "#555"}}></FaApple>;
                break;
              case "android":
                origin = <FaAndroid style={{fontSize: "14px", color: "#555"}}></FaAndroid>;
                break;
              default:
                origin = <FaChrome style={{fontSize: "10px"}}></FaChrome>;
                break;
            }

          }
          catch (e) {
          }
          

          return <div
            key={order.bagGuid}
            className={
              order.orderStatus === 4 ? "orderListRow blink" : "orderListRow"
            }
            onClick={() => {
              setOrdersData({ hasPending: false });
              view({
                view: "orderDetail_" + order.bagGuid,
                viewLoadedTime: new Date().getTime(),
              });
            }}
          >
            <div>{order.bagUser.userFullName} {origin}</div>
            {order.orderStatus !== 4 && displayAcceptedTime(order)}

            <div>
              {order.readyTime &&
                moment.utc(order.readyTime).local().format("ddd @ h:mm A")}
              {!order.readyTime &&
                moment.utc(order.submittedTime).local().format("ddd @ h:mm A")}
            </div>
          </div>
})}
      {orders.message.length > 0 && <h3>{orders.message}</h3>}
    </div>
  );
};

export default OrderList;

import { ProductsActions } from "./products.actions";
import { ProductsState } from "./products.state";

export const productsReducer = (
  state: ProductsState,
  action: ProductsActions
): ProductsState => {
  switch (action.type) {
    case "set-products-data": {
      return { ...state, ...action.data };
    }
  }
};

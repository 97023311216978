import * as React from "react";
import moment from "moment";
import { ProductsState } from "../context/data/products.state";
import OrderTime from "./OrderTime";
import {
  loadOrdersDataAction,
  saveOrderDataAction,
} from "../context/data/orders.actions";
import { LoginState } from "../context/data/login.state";
import { setAppSettingsData } from "../context/data/appSettings.actions";

interface IOrderDetailSideProps {
  order: any;
  login: LoginState;
  products: ProductsState;
  saveOrderDataAction: typeof saveOrderDataAction;
  setAppSettingsData: typeof setAppSettingsData;
  loadOrdersDataAction: typeof loadOrdersDataAction;
}

// const productItem = (cartItem: any, products: any) => {
//   // console.log(products, cartItem.ProductKey);
//   const productMatch: any = (products &&
//     products.find((s: any) => s.Key === cartItem.ProductKey)) || {
//     Key: "",
//   };

//   return <div>{JSON.stringify(productMatch.Name)}</div>;
// };

const OrderDetailSide: React.FC<IOrderDetailSideProps> = ({
  order,
  products,
  login,
  saveOrderDataAction,
  setAppSettingsData,
  loadOrdersDataAction,
}) => {
  //  const cart = JSON.parse(order.cartJSON);

  const userPhone: string = order.bagUser.userPhone;

  const userPhoneDisplay =
    userPhone.substring(0, 3) +
    "-" +
    userPhone.substring(3, 6) +
    "-" +
    userPhone.substring(6);

  return (
    <div className="orderDetailSide">
      <div className="orderDetailInfo">
        <h3>{order.bagUser.userFullName}</h3>
        <h4>{userPhoneDisplay}</h4>
        <h4>Order #: {order.iD_bagOrders}</h4>
        <h4>Type: {order.orderType.toString().toUpperCase()}</h4>
        <h4>Total: w/ Tax: ${order.grandTotal.toFixed(2)}</h4>
        {(order.orderStatus === 5 || order.isFutureOrder === 1) && (
          <h4>
            Ready Time: {moment.utc(order.readyTime).local().format("h:mm A")}
          </h4>
        )}

        {order.orderStatus === 7 && <h4>Order Rejected</h4>}
      </div>
      <div className="orderDetailButtons">
        {order.orderStatus === 4 && (
          <OrderTime
            order={order}
            login={login}
            saveOrderDataAction={saveOrderDataAction}
            setAppSettingsData={setAppSettingsData}
            loadOrdersDataAction={loadOrdersDataAction}
          ></OrderTime>
        )}
      </div>
      {/* {order.orderStatus === 5 && <div>No show</div>} */}
    </div>
  );
};

export default OrderDetailSide;

import { VendorActions } from "./vendor.actions";
import { VendorState } from "./vendor.state";

export const vendorReducer = (
  state: VendorState,
  action: VendorActions
): VendorState => {
  switch (action.type) {
    case "set-vendor-data": {
      return { ...state, ...action.data };
    }
  }
};

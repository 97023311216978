import { AppSettingsActions } from "./appSettings.actions";
import { AppSettingsState } from "./appSettings.state";

export const appSettingsReducer = (
  state: AppSettingsState,
  action: AppSettingsActions
): AppSettingsState => {
  switch (action.type) {
    case "set-app-data": {
      //    console.log(state);
      return { ...state, ...action.data };
    }
  }
};

import { ActionType } from "../types";
import { AppSettingsState } from "./appSettings.state";

export const setAppSettingsData = (data: Partial<AppSettingsState>) =>
  ({
    type: "set-app-data",
    data,
  } as const);

export type AppSettingsActions = ActionType<typeof setAppSettingsData>;

import { OrdersActions } from "./orders.actions";
import { OrdersState } from "./orders.state";

export const ordersReducer = (
  state: OrdersState,
  action: OrdersActions
): OrdersState => {
  switch (action.type) {
    case "set-orders-data": {
      return { ...state, ...action.data };
      //  return { items: [] };
    }
  }
};

import { ActionType } from "../types";
import { apiOrderData, bagOrderSetStatus } from "./api";
import { LoginState } from "./login.state";
import { OrdersState } from "./orders.state";

export const setOrdersData = (data: Partial<OrdersState>) =>
  ({
    type: "set-orders-data",
    data,
  } as const);

export const saveOrderDataAction =
  (bagGuid: string, message: string, login: LoginState) =>
  async (dispatch: React.Dispatch<any>) => {
    await bagOrderSetStatus(bagGuid, message, login);
  };

export const loadOrdersDataAction =
  (force: boolean, login: LoginState) =>
  async (dispatch: React.Dispatch<any>) => {
    if (login.ID_clients === 0)
      dispatch(
        setOrdersData({
          items: [],
          loading: false,
          message: "Please check your ID and Access Code",
        })
      );

    if (force) dispatch(setOrdersData({ items: [], loading: true }));

    const data = await apiOrderData(force, login);

    if (data) {
      dispatch(setOrdersData(data));
    }
  };

export type OrdersActions = ActionType<typeof setOrdersData>;

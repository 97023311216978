import { LoginActions } from "./login.actions";
import { LoginState } from "./login.state";

export const loginReducer = (
  state: LoginState,
  action: LoginActions
): LoginState => {
  switch (action.type) {
    case "set-login-data": {
      return { ...state, ...action.data };
    }
  }
};

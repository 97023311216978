import React from "react";
import styled from "styled-components";

const BagItemHolder = styled.div`
  border: 1px solid black;
  margin-bottom: 10px;
  border-radius: 5px;

  .itemTitle {
    background: #333333;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
  }
`;

const OptionButton = styled.div`
  background: #cdcdcd;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  box-sizing: border-box;
  float: left;
`;

interface WingDetailsProps {
  item: any;
  product: any;
}

const WingDetails = ({ item, product }: WingDetailsProps) => {
  return item.Options.filter((o: any) => o.Quantity > 0).map(
    (o: any, idx: number) => {
      return (
        <OptionButton key={`wingDetails_${idx}`}>
          {o.Name} x {o.Quantity}
        </OptionButton>
      );
    }
  );
};

interface AddonDetailsProps {
  item: any;
  product: any;
}

const AddonDetails = ({ item, product }: AddonDetailsProps) => {
  return item.Options.filter((o: any) => o.Quantity > 0).map(
    (o: any, idx: number) => {
      return (
        <OptionButton key={`addOnDetails${idx}`}>
          {o.Name} x {o.Quantity}
        </OptionButton>
      );
    }
  );
};

interface ProductDetailsProps {
  item: any;
  product: any;
}
const ProductDetails = ({ item, product }: ProductDetailsProps) => {
  let displayItems = item.Options.map((o: any, idx: number) => {
    if (o.Quantity === 0)
      return (
        <OptionButton
          key={`productDetails_${idx}`}
          style={{
            textDecoration: "line-through",
            textDecorationThickness: "30%",
          }}
        >
          {o.Name}
        </OptionButton>
      );
    else
      return (
        <OptionButton key={`productDetails_${idx}`}>{o.Name}</OptionButton>
      );
  });

  if (item.hasOwnProperty("Dressing")) { 
    const allDressing = item.Dressing.filter((d: any) => d.Quantity === 1).map((d: any) =>
      {    
      return <OptionButton key={`productDetails_dressing`}>
        {d.Name}
      </OptionButton>
      }
      );

    displayItems = [...displayItems, ...allDressing];
  };
  return displayItems;
};

function ProductView(props: any) {
  const { item, product } = props;

  return (
    <BagItemHolder>
      <div className="itemTitle">{product.Name} </div>
      <div style={{ overflow: "hidden" }}>
        {item.ProductKey.startsWith("WING") && (
          <WingDetails product={product} item={item}></WingDetails>
        )}
        {item.ProductKey.startsWith("ADDON") && (
          <AddonDetails product={product} item={item}></AddonDetails>
        )}

        {!item.ProductKey.startsWith("ADDON") &&
          !item.ProductKey.startsWith("WING") && (
            <ProductDetails product={product} item={item}></ProductDetails>
          )}
      </div>
    </BagItemHolder>
  );
}

export default ProductView;

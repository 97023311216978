import React, { useEffect, useRef } from "react";

import "./App.css";
import { useInterval } from "./components/useInterval";
import { connect } from "./context/connect";

import { AppContextProvider } from "./context/AppContext";

import { VendorState } from "./context/data/vendor.state";
import { OrdersState } from "./context/data/orders.state";
import { AppSettingsState } from "./context/data/appSettings.state";

import { loadVendorDataAction } from "./context/data/vendor.actions";
import {
  loadOrdersDataAction,
  saveOrderDataAction,
  setOrdersData,
} from "./context/data/orders.actions";
import { loadProductsDataAction } from "./context/data/products.actions";
import { setAppSettingsData } from "./context/data/appSettings.actions";

import OrderList from "./components/OrderList";
import OrderDetail from "./components/OrderDetail";
import { ProductsState } from "./context/data/products.state";
import OrderDetailSide from "./components/OrderDetailSide";

import moment from "moment";
import { LoginState } from "./context/data/login.state";
import Settings from "./components/Settings";
import {
  loadLoginDataAction,
  setLoginDataAction,
} from "./context/data/login.actions";
import { FaCog } from "react-icons/fa";
import { apiTabletCheckForRestart } from "./context/data/api";

interface StateProps {
  orders: OrdersState;
  products: ProductsState;
  vendor: VendorState;
  appSettings: AppSettingsState;
  login: LoginState;
}

interface DispatchProps {
  loadOrdersDataAction: typeof loadOrdersDataAction;
  loadVendorDataAction: typeof loadVendorDataAction;
  loadProductsDataAction: typeof loadProductsDataAction;
  setAppSettingsData: typeof setAppSettingsData;
  setOrdersData: typeof setOrdersData;
  saveOrderDataAction: typeof saveOrderDataAction;
  setLoginDataAction: typeof setLoginDataAction;
  loadLoginDataAction: typeof loadLoginDataAction;
}

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <AppConnected />
    </AppContextProvider>
  );
};

interface AppProps extends StateProps, DispatchProps {}

const AppComp: React.FC<AppProps> = ({
  orders,
  products,
  vendor,
  setOrdersData,
  appSettings,
  login,
  loadOrdersDataAction,
  loadProductsDataAction,
  loadVendorDataAction,
  saveOrderDataAction,
  setAppSettingsData,
  setLoginDataAction,
  loadLoginDataAction,
}) => {
  const audioObj: any = useRef();

  // const [audio] = useState<any>(audioObj);

  useEffect(() => {
    if (audioObj && audioObj.current)
      orders.hasPending ? audioObj.current.play() : audioObj.current.pause();
  }, [orders.hasPending]);

  useInterval(async () => {
    loadOrdersDataAction(false, login);
  }, 15000);

  useInterval(async () => {
    if (
      appSettings.view !== "orders" &&
      appSettings.currentTime - appSettings.viewLoadedTime > 120000
    ) {
      setAppSettingsData({
        currentTime: new Date().getTime(),
        viewLoadedTime: new Date().getTime(),
        view: "orders",
      });
    } else {
      setAppSettingsData({ currentTime: new Date().getTime() });
    }
  }, 60000);

  useInterval(async () => {
    const resp = await apiTabletCheckForRestart(login);

    if (resp === 1) window.location.href = "/";
  }, 180000);

  useEffect(() => {
    loadLoginDataAction();
  }, [loadLoginDataAction]);

  useEffect(() => {
    loadProductsDataAction();
    loadVendorDataAction();
    loadOrdersDataAction(true, login);
  }, [
    loadProductsDataAction,
    loadVendorDataAction,
    loadOrdersDataAction,
    login,
  ]);

  // useEffect(() => {
  //   console.log("playing:" + orders.hasPending);

  //   orders.hasPending ? audio.play() : audio.pause();
  // }, [orders.hasPending, audio]);

  const futureOrders =
    orders.items.filter((item: any) => {
      const currTime = moment(new Date()).local();
      const readyTime = moment.utc(item.readyTime).local();
      var duration = moment.duration(currTime.diff(readyTime));

      return duration.asMinutes() < 0;
    }) || [];

  return (
    <div id="mainApp" className="App">
      <audio ref={audioObj} loop>
        <source src="/media/beep.wav" />
      </audio>
      <div className="paneAction">
        <div className="paneActionTop">
          <img alt="logo" className="logo" src="/media/drlogo.png" />
          <div className="settingsIcon">
            <FaCog
              onClick={() => setAppSettingsData({ view: "settings" })}
            ></FaCog>
          </div>
          {vendor.venues && (
            <div className="venueName">
              {
                (
                  vendor.venues.find(
                    (v) => v.ID_clients === login.ID_clients
                  ) || { venueNameShort: "" }
                ).venueNameShort
              }
            </div>
          )}
          <div className="timeDisplay">
            {moment(appSettings.currentTime).local().format("h:mm A")}
          </div>
          <div className="refreshOrders">
            <button
              onClick={() => {
                loadOrdersDataAction(true, login);
                setAppSettingsData({ view: "orders" });
              }}
            >
              View Orders
            </button>
          </div>
        </div>
        <div className="paneActionBottom">
          {appSettings.view.startsWith("orderDetail") && (
            <OrderDetailSide
              login={login}
              saveOrderDataAction={saveOrderDataAction}
              setAppSettingsData={setAppSettingsData}
              loadOrdersDataAction={loadOrdersDataAction}
              products={products}
              order={orders.items.find(
                (o) =>
                  o.bagGuid === appSettings.view.replace("orderDetail_", "")
              )}
            >
              details
            </OrderDetailSide>
          )}

          {appSettings.view === "orders" &&
            futureOrders.filter(
              (item: any) => item.orderStatus === 5 && item.isFutureOrder === 1
            ).length > 0 && (
              <div className="futureOrderHeader">Upcoming Orders</div>
            )}

          <div className="futureOrderHolder">
            {appSettings.view === "orders" &&
              futureOrders
                .filter(
                  (item: any) =>
                    item.orderStatus === 5 && item.isFutureOrder === 1
                )
                .sort((a, b) => (a.readyTime > b.readyTime ? 1 : -1))
                .map((item: any, idx: number) => (
                  <button
                    onClick={() => {
                      setAppSettingsData({
                        view: "orderDetail_" + item.bagGuid,
                      });
                    }}
                    className="futureOrder"
                  >
                    {item.bagUser.userFullName} <br />
                    {moment.utc(item.readyTime).local().format("h:mm A")}
                  </button>
                ))}
          </div>
        </div>
      </div>
      <div className="paneDetail">
        {appSettings.view === "orders" && (
          <OrderList
            setOrdersData={setOrdersData}
            view={setAppSettingsData}
            orders={orders}
          ></OrderList>
        )}

        {appSettings.view.startsWith("orderDetail") && (
          <OrderDetail
            view={setAppSettingsData}
            products={products}
            order={orders.items.find(
              (o) => o.bagGuid === appSettings.view.replace("orderDetail_", "")
            )}
          ></OrderDetail>
        )}
        {appSettings.view.startsWith("settings") && (
          <Settings
            login={login}
            setOrdersData={setOrdersData}
            setLoginDataAction={setLoginDataAction}
            view={setAppSettingsData}
          ></Settings>
        )}
      </div>
    </div>
  );
};

export default App;

const AppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    orders: state.orders,
    products: state.products,
    vendor: state.vendor,
    appSettings: state.appSettings,
    login: state.login,
  }),
  mapDispatchToProps: {
    loadOrdersDataAction,
    loadVendorDataAction,
    loadProductsDataAction,
    saveOrderDataAction,
    setAppSettingsData,
    setOrdersData,
    setLoginDataAction,
    loadLoginDataAction,
  },
  component: AppComp,
});

import React from "react";
import styled from "styled-components";

const BagItemHolder = styled.div`
  border: 1px solid black;
  margin-bottom: 10px;
  border-radius: 5px;

  .itemTitle {
    background: #333333;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
  }

  > div {
    font-size: 18px;
    padding: 5px;
  }
  > div:nth-child(even) {
    background-color: #ededed;
  }

  .toppingsTitle {
    background: #333333;
    color: white;
    text-align: center;
    padding: 5px;
  }

  .toppingsHolder {
    padding: 20px;
  }
  .toppingsDetailHolder {
  }
`;

const ToppingHalfHolder = styled.div`
  display: flex;
  background: white;

  > * {
    width: 50%;
    text-align: center;
  }

  .toppingHalfOption {
    background: #cdcdcd;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .strike {
    text-decoration: line-through;
    text-decoration-thickness: 30%;
  }
`;

const ToppingFullHolder = styled.div`
  display: flex;
  flex-wrap: wrap;

  background: white;

  .toppingFullOption {
    background: #cdcdcd;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .strike {
    text-decoration: line-through;
    text-decoration-thickness: 30%;
  }
`;

function PizzaView(props: any) {
  const { item, product } = props;

  const pizzaSpecs = product.PizzaSizes.find(
    (ps: any) => ps.Key === item.ProductKey
  );

  const pizzaSetup = product.PizzaSetups.find(
    (psd: any) => psd.Key === item.OptionKey
  );

  const pizzaCrust = product.Crust.find((c: any) => c.Key === item.CrustKey);

  const sauceAmount = pizzaSetup.SauceAmount.find(
    (s: any) => s.Key === item.SauceAmount
  );

  const sauceType = pizzaSetup.Sauce.find((s: any) => s.Key === item.SauceKey);

  let isFullConfig =
    item.Toppings.filter((t: any) => t.Side === "full").length > 0;

  const toppingDetails = (pizzaSetup: any) => {
    return (
      <div className="toppingsHolder">
        <div className="toppingsTitle">Toppings</div>
        <ToppingFullHolder>
          {item.Toppings.length === 0 && (
            <div style={{ textAlign: "center", padding: "20px" }}>
              No Toppings Selected (Cheese Only)
            </div>
          )}
          {pizzaSetup.Toppings.map((t: any) => {
            const findTopping = item.Toppings.find(
              (topping: any) => topping.Key === t.Key
            );

            if (item.OptionKey === "SUPREME" || item.OptionKey === "BBQ") {
              if (findTopping) {
                return (
                  <div className="toppingFullOption">
                    {t.Name}
                    {findTopping.Quantity > 1
                      ? " x " + findTopping.Quantity
                      : ""}
                  </div>
                );
              } else {
                return <div className="toppingFullOption strike">{t.Name}</div>;
              }
            } else {
              if (findTopping) {
                return (
                  <div className="toppingFullOption">
                    {t.Name}
                    {findTopping.Quantity > 1
                      ? " x " + findTopping.Quantity
                      : ""}
                  </div>
                );
              }
            }

            return null;
          })}
        </ToppingFullHolder>
      </div>
    );
  };

  const toppingHalfDetails = (pizzaSetup: any) => {
    return (
      <div className="toppingsHolder">
        <div className="toppingsTitle">Toppings</div>
        {item.Toppings.length === 0 && (
          <div style={{ textAlign: "center", padding: "20px" }}>
            No Toppings Selected (Cheese Only)
          </div>
        )}
        <div className="toppingsDetailHolder">
          {item.Toppings.length > 0 && (
            <ToppingHalfHolder>
              <div>Left</div>
              <div>Right</div>
            </ToppingHalfHolder>
          )}
          {pizzaSetup.Toppings.map((t: any, idx: number) => {
            const findTopping = item.Toppings.filter(
              (topping: any) => topping.Key === t.Key
            );

            //   console.log(findTopping);

            if (isFullConfig) {
              if (findTopping)
                return (
                  <div key={`topping${idx}`}>
                    {t.Name} - {findTopping.Quantity}
                  </div>
                );
              else {
                if (item.OptionKey === "SUPREME" || item.OptionKey === "BBQ") {
                  return (
                    <div
                      key={`topping${idx}`}
                      style={{
                        textDecoration: "line-through",
                        textDecorationThickness: "30%",
                      }}
                    >
                      {t.Name}
                    </div>
                  );
                }
              }
            } else {
              return (
                <>
                  <ToppingHalfHolder key={`toppingHalf`}>
                    {["left", "right"].map((side: any) => {
                      const sideMatch = findTopping.find(
                        (t: any) => t.Side === side
                      );

                      if (
                        item.OptionKey === "SUPREME" ||
                        item.OptionKey === "BBQ"
                      ) {
                        return (
                          <div
                            key={`topping${side}_${idx}`}
                            className="toppingHalfOption"
                            style={{
                              textDecoration: !sideMatch ? "line-through" : "",
                              textDecorationThickness: "30%",
                            }}
                          >
                            {t.Name}
                            {sideMatch && sideMatch.Quantity > 1
                              ? " x " + sideMatch.Quantity
                              : ""}
                          </div>
                        );
                      } else {
                        if (sideMatch)
                          return (
                            <div
                              key={`topping${side}_${idx}`}
                              className="toppingHalfOption"
                            >
                              {t.Name}
                              {sideMatch && sideMatch.Quantity > 1
                                ? " x " + sideMatch.Quantity
                                : ""}
                            </div>
                          );
                      }

                      return <div key={`topping${side}_${idx}`}></div>;
                    })}
                  </ToppingHalfHolder>
                </>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  return (
    <BagItemHolder>
      <div className="itemTitle">
        Pizza: {pizzaSpecs.Name} - {pizzaSpecs.SizeInches}"
      </div>
      <div>
        <b>Type:</b> {pizzaSetup.Name}
      </div>
      <div>
        <b>Crust:</b> {pizzaCrust.Name}
      </div>
      <div>
        <b>Sauce Type:</b> {sauceType.Name}
      </div>
      <div>
        <b>Sauce Amount:</b> {sauceAmount.Name}
      </div>
      {isFullConfig && toppingDetails(pizzaSetup)}
      {!isFullConfig && toppingHalfDetails(pizzaSetup)}
    </BagItemHolder>
  );
}

export default PizzaView;

import { ActionType } from "../types";
import { apiVendorData } from "./api";
import { VendorState } from "./vendor.state";

export const setData = (data: Partial<VendorState>) =>
  ({
    type: "set-vendor-data",
    data,
  } as const);

export const loadVendorDataAction =
  () => async (dispatch: React.Dispatch<any>) => {
    const data = await apiVendorData();
    dispatch(setData(data));
  };

export type VendorActions = ActionType<typeof setData>;

import React, { ReactElement } from "react";
import { setAppSettingsData } from "../context/data/appSettings.actions";
import { LoginState } from "../context/data/login.state";
import {
  loadOrdersDataAction,
  saveOrderDataAction,
} from "../context/data/orders.actions";

interface OrderTimeProps {
  saveOrderDataAction: typeof saveOrderDataAction;
  login: LoginState;
  order: any;
  setAppSettingsData: typeof setAppSettingsData;
  loadOrdersDataAction: typeof loadOrdersDataAction;
}

export default function OrderTime({
  saveOrderDataAction,
  login,
  order,
  setAppSettingsData,
  loadOrdersDataAction,
}: OrderTimeProps): ReactElement {
  const timesToSelect = [20, 30, 40, 50, 60, 70, 80, 90];

  const save = (msg: string) => {
    saveOrderDataAction(order.bagGuid, msg, login);
    loadOrdersDataAction(true, login);
    setAppSettingsData({ view: "orders" });
  };

  return (
    <div>
      <div className="timesContainer">
        {order.isFutureOrder === 0 &&
          timesToSelect.map((t) => (
            <button onClick={() => save(t.toString())}>{t} min</button>
          ))}
        {order.isFutureOrder === 1 && (
          <button className="fullButton" onClick={() => save("Accept")}>
            Accept
          </button>
        )}
        <button className="fullButton" onClick={() => save("Reject")}>
          Reject
        </button>
      </div>
    </div>
  );
}

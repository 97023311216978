import { combineReducers } from "./combineReducers";
import { ordersReducer } from "./data/orders.reducer";
import { vendorReducer } from "./data/vendor.reducer";
import { productsReducer } from "./data/products.reducer";
import { appSettingsReducer } from "./data/appSettings.reducer";
import { loginReducer } from "./data/login.reducer";

export const initialState: AppState = {
  orders: {
    items: [],
    hasPending: false,
    lastPullHash: "",
    loading: false,
    message: "",
  },
  vendor: { venues: [] },
  products: { items: [] },
  appSettings: {
    view: "orders",
    currentTime: new Date().getTime(),
    viewLoadedTime: new Date().getTime(),
    hasBeenClicked: true,
  },
  login: { ID_clients: 0, accessCode: "", device: "", isValid: false },
};

export const reducers = combineReducers({
  orders: ordersReducer,
  vendor: vendorReducer,
  products: productsReducer,
  appSettings: appSettingsReducer,
  login: loginReducer,
});

export type AppState = ReturnType<typeof reducers>;
